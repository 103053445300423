// 夜间模式
.night-mode {
    background-color: $color-dark-primary;

    .g-banner {
        background-color: $color-dark-primary;
        color: #C8CDD3;
    }

    .post-header {
        .filter {
            opacity: .6;
        }
    }

    .article-item,
    .g-sidebar,
    .page-links,
    .search-card input,
    .search_result,
    .search_item,
    .article-item .post-tag,
    .tags-card .tag,
    .tags-list li,
    .tags-list .tag {
        color: $color-f-dark;
        background-color: $color-dark-secondary;
        border-color: rgba(255, 255, 255, 0.1);
        box-shadow: none;
    }

    .article-item {
        .post-cover img {
            filter: brightness(.6);
            -webkit-filter: brightness(.6);
        }

        .post-preview {

            .post-title,
            .post-subtitle,
            .post-excerpt {
                color: $color-f-dark;
            }
        }

        .post-meta {

            .post-tags,
            .post-date {
                color: $color-f-dark;
            }
        }
    }

    .pagination {
        .page-link {
            &:hover {
                background-color: rgba(255, 255, 255, .1);
            }
        }

        .active {
            background-color: rgba(255, 255, 255, .1);
        }
    }

    .g-sidebar {
        section {
            border-bottom: 1px solid rgba(255, 255, 255, .1);

            &:last-child {
                border-bottom: none;
            }

            .level {
                .level-item {
                    .title {
                        color: #fff;
                    }
                }
            }
        }
    }

    .avatar img {
        filter: brightness(.6);
        -webkit-filter: brightness(.6);
    }

    .author-name,
    .bio {
        color: $color-f-dark !important;
    }

    .search_result {
        .search_item {
            &:last-child {
                border-color: rgba(255, 255, 255, 0.1);
            }

            &:hover {
                background-color: transparent;
                color: #fff;
            }
        }
    }

    .article-item .post-tag,
    .tags-card .tag {
        // border: none;
        background-color: #565656;
        color: #ffffff;
    }

    .tags-card {

        .links,
        a {
            color: #d3d3d3;
        }
    }

    .tags-list {
        li {

            .tag-name,
            .tag-post {
                color: $color-f-dark;
            }
        }
    }

    .latest-articles {
        a {
            color: #ffffff;
        }
    }

    .g-footer {
        border-color: rgba(255, 255, 255, 0.1);
        background-color: transparent;

        section {
            color: $color-f-dark;

            a {
                color: #f8f8f8;

                &:hover {
                    color: #83888f;
                }
            }

            .links {
                a {
                    color: $color-f-dark;

                    &:before {
                        position: absolute;
                        top: 80%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(21, 101, 192, .6);
                        transition: top .2s cubic-bezier(0, .8, .13, 1);
                        z-index: 1;
                    }
                }
            }
        }
    }

    .post-content {
        background-color: $color-dark-primary;
        border-color: rgba(255, 255, 255, .1);

        .post-subtitle {
            color: #ffffff;

            &:before,
            &:after {
                background-color: #575B63;
            }
        }

        .submenu {
            color: #ffffff;

            a {
                color: #ffffff;
            }
        }
    }

    .markdown-body {
        color: $color-f-dark;
        background-color: transparent;

        img {
            filter: brightness(.6);
            -webkit-filter: brightness(.6);
        }

        blockquote {
            border-left-color: #555A61;
        }

        pre {
            border: 3px solid #393939;
            border-radius: 0px;
            border-top: none;

            code {
                text-shadow: none !important;

                .line-numbers-rows {
                    border-right: 1px solid #9d9d9f;
                }
            }
        }

        a {
            color: $color-f-dark;

            &:not([ref="gallery"]):before {
                position: absolute;
                top: 80%;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(21, 101, 192, .6);
                transition: top .2s cubic-bezier(0, .8, .13, 1);
                z-index: 1;
            }
        }

        .copy-container {
            background-color: #393939;
        }

        .language-plaintext {
            color: chocolate;
        }

        .caption {
            color: #aaaaaa;
        }

        table tr {
            background-color: transparent;
        }

        #archive-articles {
            ul {
                li:before {
                    background: #aaaaaa;
                }
            }
        }
    }

    .table-of-contents,
    .table-of-contents a {
        color: #ffffff;
    }

    .read-next-item {
        background-color: #484D59;

        section {
            color: #AEB4BD;
        }

        .filter {
            background-color: rgba(0, 0, 0, .6);
        }
    }

    :not(pre)>code[class*="language-"],
    pre[class*="language-"] {
        // background-color: $color-dark-secondary;
        background-color: #efefef;
    }

    code[class*="language-"],
    pre[class*="language-"],
    .token.punctuation,
    .token.operator,
    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string,
    .token.variable {
        color: #8F97A7;
        background: none;
    }

    .token.atrule,
    .token.attr-value,
    .token.function {
        color: #A9A054;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
        color: #84AC35;
    }

    .token.property,
    .token.tag,
    .token.constant,
    .token.symbol,
    .token.deleted {
        color: #D75885;
    }

    .token.keyword {
        color: #61BDCF;
    }

    #tools .tool,
    .bttb .tool {
        background-color: #444950;
        color: #AFB3B7;
        &:hover {
            background-color: #606770;
        }
    }

    .author-detail,
    .atk-dropdown-wrap,
    #waline button,
    .wl-editor,
    .wl-input,
    .wl-info .wl-text-number,
    .wl-content p,
    .wl-panel label,
    .wl-card .wl-meta span {
        color: #ffffff;
    }

    .wl-panel {
        background-color: #282C34;
    }

    .wl-editor:focus,
    .wl-input:focus {
        background: #252934;
    }

    .wl-card .wl-meta span {
        background: #272727;
    }

    .atk-main-editor,
    .atk-textarea,
    .atk-fade-in,
    .atk-grp-switcher {
        background: #252934 !important;
        color: #ffffff!important;
    }

    .atk-grp-switcher {
        span:hover,
        span.active {
            color: #252934;
        }
    }

    .artalk {
        color: #ffffff;
    }

    .atk-actions {
        span:hover {
            color: #0083ff!important;
        }
    }

    .tk-footer {
        a {
            color: #ffffff;
            &:hover {
                color: #0083ff;
            }
        }
    }

    .archives-date {
        color: #eff0f0;
    }

    .year {
        span {
            background-color: #ffffff;
        }
    }
}