/*** Main style ***/

@import 'navbar';
@import 'markdown';
@import 'post';
@import 'tools';
@import 'theme';
@import 'night';

.g-container {
    width: $g-container-w;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.extlinks::after {
        content: "↗️";
        font-size: 12px;
        padding: 0 2px;
        vertical-align: 1px;
    }
}

.home-content {
    margin: 40px auto 60px;
    @include clearfix;
}

.article-list {
    width: $post-content-w;

    .author-detail {
        top: 20px !important;
    }

    .article-item {
        width: 100%;
        margin-bottom: 16px;
        @include cardStyle;

        &:last-child {
            margin-bottom: 0;
        }

        .post-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .post-cover {
            position: relative;
            width: 100%;
            max-height: 300px;
            margin-bottom: 14px;
            overflow: hidden;

            img {
                width: 100%;
                vertical-align: middle;
            }
        }

        .post-preview {
            position: relative;

            .post-title {
                font-family: $fonts;
                color: $color-f-primary;
                font-size: 24px;
                line-height: 32px;

                .icon {
                    width: 28px;
                    height: 28px;
                    margin-bottom: -5px;
                }
            }

            .post-subtitle {
                margin-top: 3px;
                font-size: 18px;
                font-weight: normal;
                color: #585858;
                line-height: 26px;
            }

            .post-excerpt {
                margin-top: 8px;
                font-size: 16px;
                font-family: 'Merriweather', $fonts;
                line-height: 26px;
                word-break: break-all;
                // color: #b8bdc3;
                color: #585858;
            }
        }

        .post-meta {
            min-height: 22px;
            margin-top: 8px;
            display: flex;
            justify-content: space-between;

            .post-tags {
                display: inline-block;
                width: 515px;
            }

            .post-date {
                @include timeStyle;

                .icon {
                    width: 16px;
                    height: 16px;
                    margin-bottom: -3px;
                }
            }
        }
    }
}

.author-card {
    @include authorCard;
}

.article-item .post-meta .post-tag,
.tags-card .tag,
.tags-content .tag {
    @include tagStyle;
}

.tags-card .links {
    width: 100%;
    font-size: 16px;
    color: #4e4e4e;
    font-weight: bold;
    margin: 10px 0 16px;
    text-align: center;
}

.g-sidebar-wrapper {
    width: 300px;
    position: sticky;
    top: 100px;
    height: fit-content;
}

.g-sidebar {
    @include cardStyle;

    section {
        padding: 10px 0;
        border-bottom: 1px solid #E7EAF1;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        .tag {
            margin-bottom: 8px;
        }

        .icon {
            width: 30px;
            height: 16px;
            margin-bottom: -3px;
            fill: currentColor;
            overflow: hidden;
        }

        .latest-articles {
            li {
                margin-bottom: 15px;
                font-size: 14px;

                .latest-title {
                    margin: 5px 0;
                    text-align: justify;
                    white-space: nowrap;
                    width: 265px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .latest-date {
                    font-size: 12px;
                }
            }
        }

        .calendar-top {
            margin: 10px auto;

            .button {
                border-radius: 5px;
                padding: 3px 5px;
                display: inline-block;

                &:hover {
                    background-color: #ebedf0;
                }

                .icon {
                    width: 16px;
                }
            }

            .legends {
                float: right;
                font-size: 12px;
                margin: 5px;
                min-width: 100px;

                .text {
                    color: #768390;
                    font-size: 11px;
                }

                #ex-ghDay-legend {
                    display: inline-block;
                    margin: 0 4px;
                }
            }
        }
    }
}

li#search {
    margin: 8px 5px;
}

.DocSearch-Container {
    z-index: 1002;
}

.search-card {
    position: relative;
    margin-top: 16px;
    padding: 0;

    input,
    .search_item {
        font-size: 16px;
        color: $color-f-primary;
    }

    input {
        @include cardStyle;
        position: relative;
        padding: 12px 46px 12px 18px;
        margin: 0;
        width: 100%;
        background-color: #fff;
        outline: none;
        z-index: 1;
    }

    ::-webkit-input-placeholder {
        color: #9EA8B3;
    }

    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #9EA8B3;
    }

    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #9EA8B3;
    }

    .icon-search {
        position: absolute;
        top: 10px;
        right: 18px;
        font-size: 26px;
        color: #CAD3DC;
        transition: .2s;
        z-index: 1;
    }

    .icon {
        position: absolute;
        top: 13px;
        right: 18px;
        z-index: 1;
        width: 20px;
        height: 20px;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }

    .search_result {
        position: relative;
        margin-top: -2px;
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        z-index: 0;
    }

    .search_item {
        padding: 6px 18px;
        margin-bottom: 0;
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-left: 1px solid #E7EAF1;
        border-right: 1px solid #E7EAF1;
        box-sizing: border-box;
        opacity: .8;
        transition: .2s;

        &:first-child {
            padding-top: 14px;
        }

        &:last-child {
            padding-bottom: 14px;
            border-radius: 0 0 4px 4px;
            border-bottom: 1px solid #E7EAF1;
        }

        &:hover {
            opacity: 1;
            background-color: #FAFAFA;
        }
    }
}

.pagination {
    float: left;
    position: relative;
    left: 50%;
    margin: 8px auto 0;

    .page-links {
        position: relative;
        left: -50%;
        border: 1px solid #E7EAF1;
        border-radius: 3px;
        background-color: #fff;
        padding: 0 8px;
        overflow: auto;
        box-shadow: 0 1px 3px rgba(0, 37, 55, .06);
    }

    .page-link {
        display: block;
        float: left;
        width: 34px;
        height: 34px;
        margin: 6px;
        font-size: 16px;
        color: #A9B0BC;
        line-height: 34px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
            color: #8F98AA;
            background-color: #EEF0F4;
        }
    }

    .active {
        color: #8F98AA;
        background-color: #EEF0F4;
    }

    #page-link-container {
        float: left;
    }
}

.g-footer {
    min-height: $g-footer-h;
    padding: 20px 0;
    border-top: 1px solid #E7EAF1;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 -1px 3px rgba(0, 37, 55, .06);

    .g-container {
        margin: 0 auto;
        @include clearfix;

        .g-left {
            width: 500px;
            text-align: left;
        }

        .g-right {
            width: 500px;
            text-align: right;

            .badges {
                margin-top: 10px;
            }
        }
    }

    section {
        font-size: 12px;
        line-height: 18px;
        color: #4E4E4E;

        a {
            color: #4E4E4E;

            &.others {
                margin-left: 10px;
            }

            &:hover {
                color: #83888F;
            }

            .license {
                border-width: 0px;
                margin-bottom: -3px;
                width: 80px;
                height: 15px;
            }

        }

        .icon {
            width: 14px;
            height: 14px;
            margin-bottom: -3px;
            fill: currentColor;
            overflow: hidden;
        }

        .upyun {
            height: 25px;
            margin-bottom: -8px;
        }

        .netlify img {
            height: 20px;
            background: #ffffff;
            padding: 5px 10px;
            border-radius: 3px;
        }

        .vercel img {
            height: 16px;
            margin-left: 10px;
            background: #ffffff;
            padding: 7px 10px;
            border-radius: 3px;
        }

        .cloudflare img {
            height: 20px;
            margin-left: 10px;
            background: #ffffff;
            padding: 5px 10px;
            border-radius: 3px;
        }

        .foreverblog img {
            width: auto;
            height: 16px;
            vertical-align: super;
            margin: 10px 10px 0 0;
        }

        .wormhole img {
            width: auto;
            height: 24px;
            margin-right: 10px;
        }

        .travelling-badge img {
            width: 120px;
            margin-right: 10px;
        }

        .notbyAI img {
            width: 100px;
        }
    }
}

.tags-banner {
    height: 300px;
    text-align: center;

    h2 {
        font-size: 36px;
        line-height: 300px;
    }
}

.tags-content {
    width: $post-content-w;
    margin: -100px auto 0;
}

.tags-list {
    padding: 40px 0;

    li {
        @include cardStyle;
        margin-bottom: 12px;

        &:first-child {
            margin-bottom: 24px;
            cursor: default;
        }

        .tag {
            box-shadow: 0 0 .3px #BFC2C6;
            margin-bottom: 12px;
            white-space: nowrap;
        }

        .tag-name {
            display: block;
            font-size: 20px;
            color: #333;
            text-align: center;
            margin-bottom: 20px;
        }

        .tag-post {
            display: block;
            font-size: 16px;
            color: #666;
            text-align: center;
            margin: 16px auto;
        }
    }

}

// 404 page
.np-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    text-align: center;

    h1 {
        font-size: 86px;
        letter-spacing: 4px;
        font-weight: normal;
        padding: 160px 0 20px;
    }

    .subheading {
        font-size: 18px;
    }

    .btn {
        display: block;
        width: 160px;
        height: 38px;
        font-size: 16px;
        line-height: 38px;
        border-radius: 20px;
        border-width: 3px;
        border-style: solid;
        margin: 80px auto 0;
    }

}

.year {
    span {
        float: right;
        width: 50px;
        height: 25px;
        text-align: center;
        border-radius: 2px;

        .icon {
            &.animal {
                width: 25px;
                height: 25px;
            }

            &.dz {
                width: 15px;
                height: 15px;
                margin-bottom: 10px;
                margin-left: -5px;
            }
        }
    }
}

.g-footer {
    .links {
        a {
            color: #24292f;
            position: relative;
            text-decoration: none;
            z-index: 2;

            &:hover {
                text-decoration: none;

                &:before {
                    top: 0;
                }
            }
        }
    }

    #busuanzi_site_pv,
    #busuanzi_site_uv,
    #busuanzi_value_site_pv,
    #busuanzi_value_site_uv {
        color: brown;
    }
}
