@media screen and (min-width: 1920px) {
    .home-banner {
        height: 600px;
        h2 {
            padding-top: 260px;
            font-size: 42px;
        }
        h3 {
            font-size: 22px;
        }
    }
}

@media screen and (max-width: $g-container-w) {
    .g-container {
        width: auto;
    }
    .article-list {
        width: 70%;
    }
    .post-content {
        .container {
            width: auto;
            .contents {
                width: 70%;
            }
        }
    }
    .pagination  {
        .page-num {
            display: none;
        }
        .page-link {
            width: 56px;
        }
    }
}

@media screen and (max-width: $g-container-l) {
    .post-content .container {
        width: 90%;
    }
    .night-mode {
        .post-content {
            .submenu, .table-of-contents {
                background-color: #606770;
            }
        }
    }
    .submenu {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        right: 20.8px;
        bottom: 140px;
        top: auto;
        background-color: aliceblue;
        z-index: 1010;
        max-width: 75%;
        max-height: 60%;
        overflow: scroll;
        &.active {
            visibility: visible;
            opacity: 1;
            transition: .5s;
            border-radius: 0px;
        }
    }
    #tools {
        visibility: visible;
        .tool.toc {
            visibility: hidden;
        }
    }
}

@media screen and (max-width: 1050px) {
    #all {
        overflow: hidden;
    }
    .article-list {
        width: 95%;
    }
    .g-sidebar-wrapper {
        display: none;
    }
    .g-footer .g-container {
        margin: auto 20px;
    }
    .post-wrapper {
        width: auto;
        padding: 30px;
    }
    .post-content {
        .post-subtitle {
            width: auto;
        }
        .container {
            width: 100%;
            .contents {
                width: 90%;
                float: none;
                margin: 0 auto;
            } 
        }
    }
    #tools .tool.toc {
        visibility: visible;
    }
    .table-of-contents {
        visibility: hidden;
        opacity: 0;
        border-left: none;
        position: fixed;
        right: 20.8px;
        top: auto;
        bottom: 157px;
        margin-left: 0px;
        max-width: 95%;
        background-color: aliceblue;
        z-index: 1010;
        margin-bottom: 5px;
        padding: 25px;
        .toc-body {
            height: 300px;
        }
        &.active {
            visibility: visible;
            opacity: 1;
            transition: .5s;
        }
    }
    .read-next {
        margin: 20px;
        width: auto;
    }
    .post-footer-item{
        width: auto;
    }
    .post .mobile-list {
        visibility: visible;
    }
}

@media screen and (max-width: 960px) {

    .archives-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: -8px!important;
    }
    
    .tags-content {
        width: auto;
    }
}

@media screen and (max-width: 810px) {
    .g-footer .g-container {
        flex-direction: column;
        .g-left, .g-right {
            width: auto;
            text-align: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .post-wrapper, .markdown-body, .read-next {
        width: auto;
    }
    .post-content {
        .post-subtitle {
            width: 92%;
        }
    }
    .author-detail {
        .author-card {
            width: 92%;
        }
    }
}

@media screen and (max-width: 695px) {
    .g-header {
        position: absolute;
        top: 0;
        padding: 28px 0;
        #search-toggle, #random-toggle, #travelling-toggle, #mode-toggle {
            display: block;
        }
        .icon-menu {
            display: block;
            width: 40px;
            height: 28px;
        }
        .g-nav {
            position: relative;
            top: 48px;
            right: 1%;
            display: none;
            min-width: 152px;
            background-color: #fff;
            border-radius: 2px;
            padding: 8px 16px;
            box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
            &>ul>li{
                float: none;
                height: auto;
                line-height: normal;
                margin-right: 0;
                &>a {
                    display: block;
                    padding: 16px 0;
                    font-size: 16px;
                    font-weight: bold;
                    color: #A5A8B0;
                    opacity: 1;
                }
                &#search, &.random, &.travelling, &.mode{
                    display: none;
                }
            }
            ul.dropdown-menu {
                box-shadow: none;
                position: relative;
                float: none;
                li {
                    padding: 10px;
                    line-height: normal;
                    a {
                        text-align: left;
                    }
                }
            }
        }
    }

    .author-detail {
        .author-card {
            .sns-links {
                margin-top: 24px;
                li {
                    width: 36px;
                    height: 36px;
                    .iconfont {
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }
        }
        .comment {
            width: 90%;
        }
    }

    .read-next {
        display: block;
    }
    .read-next-item {
        margin-bottom: 16px;
        margin-left: 0;
        &:first-child {
            margin-right: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            top: 0;
            box-shadow: none;
        }
        section {
            width: 90%;
            margin-left: 5%;
            margin-top: 45px;
        }
    }

    .g-banner {
        padding: 0 20px;
    }
    .home-banner, .tags-banner {
        h3 {
            margin-top: 8px;
        }
    }
    .home-content {
        margin-top: 0;
    }
    .article-list, .tags-content, .g-footer .g-left, .g-footer .g-right {
        width: 100%;
    }
    .tags-content {
        margin-top: 0;
    }
    .tags-banner {
        height: 300px;
    }
    .tags-list {
        padding: 0 0 24px;
    }

    .g-footer {
        padding: 30px 0;
    }

    .post-meta-item {
        .icon {
            &.info2{
                display: none;
            }
        }
    }

    #accept-tos {
        float: none;
        display: block;
        margin: auto;
    }

    .friends {
        .frienddiv {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .home-banner {
        height: 400px;
        h2 {
            padding-top: 180px;
            font-size: 28px;
            line-height: 48px;
        }
        h3 {
            margin-top: 2px;
            font-size: 18px;
        }
    }
    .home-content {
        margin-bottom: 32px;
        flex-direction: column;
    }
    .g-sidebar-wrapper {
        display: block;
        margin: 0 auto;
        width: 90%;
        min-width: 300px;
        .g-sidebar {
            margin-top: 30px;
        }
    }  
    .article-list {
        .article-item {
            padding: 20px 16px;
            margin-bottom: 8px;
            border-left: none;
            border-right: none;
            border-radius: 0;
            box-shadow: none;
            &:first-child {
                border-top: none;
            }
            .post-cover {
                max-height: 200px;
            }
            .post-preview {
                .post-title {
                    font-size: 18px;
                    line-height: 26px;
                }
                .post-subtitle {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .post-meta {
                margin-top: 12px;
                .post-tags {
                    display: none;
                }
                .post-date {
                    float: left;
                    margin-left: 5px;
                }
            }
        }
    }
    .pagination {
        .page-links {
            box-shadow: none;
        }
    }
    .post-header{
        padding: 0;
        min-height: 360px;
        height: auto;
    }
    .post-wrapper {
        .post-tags {
            margin: 100px 0 8px;
        }
        .post-meta {
            margin-top: 16px;
            padding-bottom: 20px;
        }
    }
    .post-content {
        padding-top: 20px;
        .post-subtitle {
            padding: 8px 0 24px;
            margin-bottom: 32px;
            &:before {
                height: 0;
            }
        }
        .container {
            .contents {
                padding: 0;
            }
        }
    }
    .markdown-body {
        p, ul>li {
            font-size: 16px;
            font-weight: 300;
            -webkit-font-smoothing: auto;
            -moz-osx-font-smoothing: auto;
        }
        h1, h2, h3, h4 {
            margin-top: 24px;
        }
        table {
            margin: 16px auto;
        }
        img {
            margin: 16px 0 auto;
        }
        strong {
            font-weight: 400;
        }
        blockquote {
            p {
                font-size: 18px;
                letter-spacing: 1px;
                color: #888;
                word-break: break-all;
            }
        }
        mjx-container {
            overflow-x: scroll;
        }
    }
}

@media screen and (max-width: 376px) {
    .home-banner {
        h2 {
            font-size: 26px;
            line-height: 38px;
        }
        h3 {
            margin-top: 4px;
            font-size: 16px;
        }
    }  

}

@media (hover: hover){
    #accept-tos {
        &:hover {
            background-color: #71a7ff;
        }
    }
    .markdown-body{
        .btn {
            &:hover {
                background-color: #dadde1;
            }
        }
    }
}
